import { Injectable } from "@angular/core";
import { Subject } from "rxjs/internal/Subject";

@Injectable({
  providedIn: "root"
})
export class RaqService {
  private sendRAQ = new Subject<any>();

  constructor() {}

  get getSendRaqResponse() {
    return this.sendRAQ.asObservable();
  }

  set setSendRaqResponse(value) {
    this.sendRAQ.next(value);
  }
}
