
const Utils = () => { };

// convert to 12/31/19
Utils.formatDateToMdyy = (fullDate) => {
    const date = new Date(fullDate);
    const formattedDate = (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear().toString().substr(-2);
    return formattedDate;
};

/**
 * To validate the entered key is number or not.
 */
Utils.validateNumber = (event) => {
    return (event.ctrlKey
        || event.altKey
        || (event.keyCode > 47 && event.keyCode < 58 && event.shiftKey == false)
        || (event.keyCode > 95 && event.keyCode < 106)
        || (event.keyCode == 8) || (event.keyCode == 9)
        || (event.keyCode > 34 && event.keyCode < 40)
        || (event.keyCode == 46))
};

export default Utils;
